export default {
  firebase: {
    apiKey: "AIzaSyCktxUWP5IlMOB_-yeEmCmO3t1ncREdufc",
    authDomain: "visit-io-dev.firebaseapp.com",
    databaseURL: "https://visit-io-dev.firebaseio.com",
    projectId: "visit-io-dev",
    storageBucket: "visit-io-dev.appspot.com",
    messagingSenderId: "672265476044",
    appId: "1:672265476044:web:1e97c1f3ae643e6d62ef69",
    measurementId: "G-XTNHW8X5V4"
  },
  analytics: {
    trackingId: "UA-153499921-2"
  },
  functions: {
    app: "https://us-central1-visit-io-dev.cloudfunctions.net/app"
  },
  stripe: {
    pubApiKey: "pk_test_WwRjtdDPoXVESsZmZ0SJfcPm00YXjK6bFr"
  },
  hosting: {
    domain: "speeltuin.babybezoek.be"
  },
  bugsnag: {
    apiKey: "2723b609724fc70d967ea0ade6d824aa",
    releaseStage: "development"
  },
  webFonts: {
    apiKey: "AIzaSyDAnolat3i-3lsqyroWiRgKsynugyLgjLI"
  }
};
